import React, { useState, useContext, useEffect } from 'react';
import { MgmSynagogueDataContext } from '../../provider/mgmSynagogueDataProvider';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import Loader from '../../componnents/shared/Loader';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

const SECRET_KEY = 'YOUR_SECRET_KEY'; // Use a strong key (but storing it here is not ideal for real security)

const SignIn = () => {
  const { synagogueData, isLoading, error, setIsLoading, fetchData } = useContext(MgmSynagogueDataContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);

  const navigate = useNavigate();

  // Check if there are stored credentials in localStorage and try to auto-login
  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    console.log("🚀 ~ useEffect ~ storedEmail:", storedEmail)
    const storedPassword = localStorage.getItem('password');
    console.log("🚀 ~ useEffect ~ storedPassword:", storedPassword)

    // If found in localStorage, attempt decrypt and auto fetch
    if (storedEmail && storedPassword) {
      const decryptedEmail = CryptoJS.AES.decrypt(storedEmail, SECRET_KEY).toString(CryptoJS.enc.Utf8);
      console.log("🚀 ~ useEffect ~ SECRET_KEY:", SECRET_KEY)
      console.log("🚀 ~ useEffect ~ decryptedEmail:", decryptedEmail)
      const decryptedPassword = CryptoJS.AES.decrypt(storedPassword, SECRET_KEY).toString(CryptoJS.enc.Utf8);
      console.log("🚀 ~ useEffect ~ decryptedPassword:", decryptedPassword)

      if (decryptedEmail && decryptedPassword) {
        setIsLoading(true);
        fetchData(decryptedEmail, decryptedPassword);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignIn = (event) => {
    event.preventDefault(); // Prevent default form submission
    setIsLoading(true);
    const encryptedEmail = CryptoJS.AES.encrypt(email, SECRET_KEY).toString();
    const encryptedPassword = CryptoJS.AES.encrypt(password, SECRET_KEY).toString();

    localStorage.setItem('email', encryptedEmail);
    localStorage.setItem('password', encryptedPassword);
    // Attempt to log in with provided credentials
    fetchData(email, password);
  };

  useEffect(() => {
    if (error) {
      clearTimeout(timeoutId); // Clear timeout if there's an error
      localStorage.clear();
      setErrorMessage('אירעה שגיאה, אנא נסה שוב');
      setIsLoading(false);
    }
  }, [error, timeoutId]);

  useEffect(() => {
    // If synagogueData is successfully fetched (meaning login was successful),
    // store the credentials in localStorage (encrypted), then navigate.
    if (synagogueData) {
      clearTimeout(timeoutId);

      // Encrypt and store email and password in localStorage


      navigate("/mgm");
    }
  }, [synagogueData, email, password, navigate, timeoutId]);

  if (isLoading) {
    return <Loader style={customStyle} />;
  }

  return (
    <form onSubmit={handleSignIn} className="sign-in-form">
      <h2 className="title">הכנס</h2>
      <div className="input-field">
        <FontAwesomeIcon icon={faUser} />
        <input
          type="email"
          id="email"
          placeholder="הזן את כתובת האימייל שלך"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="input-field">
        <FontAwesomeIcon icon={faLock} />
        <input
          type="password"
          placeholder="הזן סיסמה"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <input type="submit" value="הכנס" className="btn solid" />
    </form>
  );
};

export default SignIn;

const customStyle = {
  height: '40vh',
};
