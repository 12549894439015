import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BaseContainer } from "../../componnents/baseTags/BaseContainer";
import HorizontalNavScroll from "../../componnents/baseTags/HorizontalNavScroll";
import BackButton from '../../componnents/shared/BackButton';
import EditCards from '../../componnents/shared/EditCards';
import { MgmSynagogueDataContext } from "../../provider/mgmSynagogueDataProvider";

import { cardMsgsConfig } from '../../data/config';


export default function MgmMessages() {
    const navigate = useNavigate();
    const { synagogueData } = useContext(MgmSynagogueDataContext);
    const synagogueID = synagogueData?.synagogues?.id;
    


    const [filteredData, setFilteredData] = useState([]);
    const [newCard, setNewCard] = useState(null);

    const createNewCard = () => ({
        value: '',   
        index: filteredData.length + 1,
        lastUpdate: new Date(), 
        synagogueID: synagogueID,

    });



    useEffect(() => {
        const filteredMsgs = synagogueData.synagogueMessages.filter(msg => msg.synagogueID === synagogueID);
        setNewCard(createNewCard());
        setFilteredData(filteredMsgs);
    }, [synagogueData]);

    

    const headers = ['value','index'];

    return (
        <BaseContainer style={styles.container}>
            <BackButton title="עריכת הודעות" />
            <BaseContainer style={styles.cardContainer}>
   
                <EditCards cardData={filteredData} headerFields={headers} newCard={newCard} collectionName="synagogueMessages" cardConfig={cardMsgsConfig} synagogueID={synagogueID} />

            </BaseContainer>
        </BaseContainer>
    );
}


const styles = {
    container: {
        width: '90%',
        margin: '20px auto',
    },
    cardContainer: {
        marginTop: '20px',
        marginBottom:'100px'

    }
};
