import React, { useState, useCallback, useEffect, useContext } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import EditCard from './EditCard';
import useHttpRequest from '../../hooks/useHttpRequest';
import { mgmApi } from '../../api/apiCalls';
import { MgmSynagogueDataContext } from "../../provider/mgmSynagogueDataProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const EditCards = ({
  displayCategory,
  setDisplayCategory, // Receive the setter as a prop
  cardData,
  headerFields,
  newCard,
  collectionName,
  cardConfig,
  synagogueID,
  showAddAndRemoveButton = true,
  openAlways = false,
  editCategory = false
}) => {

  const [data, setData] = useState(cardData);
  const [deletedCardIds, setDeletedCardIds] = useState([]);
  const { performRequest: createUpdateDocuments } = useHttpRequest(mgmApi.createUpdateDocuments);
  const { fetchData, synagogueData, UpdateContext } = useContext(MgmSynagogueDataContext);
  const [isSaving, setIsSaving] = useState(false);
  const [isChangeCategory, setIsChangeCategory] = useState(false); // מצב לערך המקורי
  const [hasChanges, setHasChanges] = useState(false); // מצב חדש לבדיקת שינויים במידע
  const email = synagogueData.synagogues.email;
  useEffect(() => {
    const sortedData = [...cardData].sort((a, b) => a.index - b.index);
    setData(sortedData);
    setHasChanges(false);
    setIsChangeCategory(false);
  }, [cardData]);

  // useEffect(() => {

  //   setData(synagogueData);

  // }, [synagogueData]);

  const [openCardId, setOpenCardId] = useState(null);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({ ...item, index: index + 1 }));
    setData(updatedItems);
    setHasChanges(true);
  };

  const handleInputChange = useCallback((cardId, key, value) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === cardId
          ? { ...item, [key]: value, hasChanged: true } // Mark item as changed
          : item
      )
    );
    setHasChanges(true);
  }, []);

  const handleDeleteCard = (cardId) => {
    setDeletedCardIds((prevIds) => [...prevIds, cardId]);
    setData((prevData) => prevData.filter((item) => item.id !== cardId));
    setHasChanges(true);
  };


  const handleSave = () => {
    if (isSaving) return;
    setIsSaving(true);

    const changedItems = data.filter((item) => item.hasChanged || deletedCardIds.includes(item.id));
    const deletedCards = deletedCardIds.map((id) => ({ id }));

    const combinedData = [...changedItems, ...deletedCards];
    console.log("🚀 ~ handleSave ~ combinedData:", combinedData)

    const combinedData1 = [
      changedItems.length > 0 && {
        type: "changed",
        items: changedItems.map((item) => ({
          id: item.id,
          updateDate: new Date().toISOString(),
        })),
      },
      deletedCards.length > 0 && {
        type: "deleted",
        items: deletedCards.map((item) => ({
          id: item.id,
          updateDate: new Date().toISOString(),
          isDeleted: true,
        })),
      },
    ].filter(Boolean);

    var newOutdatedFields = combinedData1.map((group) => ({
      collectionName: collectionName,
      updateDate: new Date().toISOString(),
      isDeleted: group.type === "deleted",
    }));
    console.log("🚀 ~ handleSave ~ newOutdatedFields:", newOutdatedFields)



    if (isChangeCategory && editCategory) {

      createUpdateDocuments([displayCategory], "synagogueCategoriesPrayers", synagogueID); 
    
      newOutdatedFields.push({
        collectionName: "synagogueCategoriesPrayers", 
        updateDate: new Date().toISOString(),
        isDeleted: false, 
      });
    
    }

    createUpdateDocuments(combinedData, collectionName, synagogueID)
      .then(() => {
        Swal.fire({
          title: 'נשמר בהצלחה',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        // Update the local state with the saved 
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        Swal.fire({
          title: 'שגיאה בשמירה',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      })
      .finally(() => {

        UpdateContext(synagogueID, newOutdatedFields)
        newOutdatedFields = null
        setIsSaving(false);
        setHasChanges(false);
      });

  };

  const handleAddCard = () => {
    const cardWithId = {
      ...newCard,
      id: Math.random().toString(36).substr(2, 9), // Generate a unique ID for the new card
    };

    setData((prevData) => [...prevData, cardWithId]);
    setOpenCardId((prevId) => (prevId === cardWithId.id ? null : cardWithId.id));

    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth', // Smooth scrolling
      });
    }, 0); // Timeout ensures scrolling happens after the DOM update
  };

  const toggleCard = (cardId) => {
    setOpenCardId((prevId) => (prevId === cardId ? null : cardId));
  };

  useEffect(() => {
    if (openCardId) {
      setOpenCardId(openCardId);
    }
  }, [data, openCardId]);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      {editCategory && (
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse', alignItems: 'center' }}>
          <div style={styles.editTitle} class="form-floating">
            <input
              type="text"
              class="form-control"
              style={styles.editTitleIput}
              value={displayCategory.value}
              onChange={(e) => {
                setIsChangeCategory(true)
                setHasChanges(true)
                setDisplayCategory((prev) => ({
                  ...prev,
                  value: e.target.value,
                }))
              }
              } />
            <label style={styles.editTitleLabel}>כותרת לתצוגה על המסך</label>
          </div>
          <div style={styles.inputCheckbox} className="form-check form-switch">
            <input
              type="checkbox"
              className="form-check-input"
              onClick={(e) => e.stopPropagation()}
              checked={displayCategory.display}
              onChange={(e) => {
                setIsChangeCategory(true)
                setDisplayCategory((prev) => ({
                  ...prev,
                  display: e.target.checked,
                }))
              }}
            />
          </div>
        </div>
      )}


      <Droppable droppableId="cards">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={styles.cardContainer}>
            {data.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <EditCard
                      item={item}
                      isOpen={openCardId === item.id}
                      toggleCard={toggleCard}
                      onChange={handleInputChange}
                      onDelete={handleDeleteCard}
                      headerFields={headerFields}
                      showAddAndRemoveButton={showAddAndRemoveButton}
                      cardConfig={cardConfig}
                      openAlways={openAlways}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <button
        style={{
          ...styles.saveButton,
          backgroundColor: isSaving || !hasChanges ? '#ccc' : 'rgb(19, 96, 161)', // צבע לחוש
          cursor: isSaving || !hasChanges ? 'not-allowed' : 'pointer', // שינוי צורת העכבר
        }}
        onClick={handleSave}
        disabled={isSaving || !hasChanges} // הכפתור יהיה מושבת אם אין שינויים
      >
        {isSaving ? (
          <FontAwesomeIcon icon={faSpinner} spin /> // אייקון מסתובב
        ) : (
          <FontAwesomeIcon icon={faSave} /> // אייקון שמירה רגיל
        )}
        <span style={styles.saveText}>שמירה</span>
      </button>
      {showAddAndRemoveButton && (
        <button style={styles.addButton} onClick={handleAddCard}>
          <FontAwesomeIcon icon={faPlus} />      <span>הוספה</span>
        </button>
      )}
    </DragDropContext>
  );
};

export default EditCards;


const styles = {
  cardContainer: {},
  editTitle: {
    display: 'flex',
    flexDirection: 'row-reverse',
    fontSize: '1.2em',
    padding: '8px 0 8px 20px',
    direction: 'rtl',
    width: '40%',
    minWidth: '250px'
  },
  saveButton: {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    backgroundColor: 'rgb(19, 96, 161)', // שינוי צבע הרקע פעם אחת בלבד
    color: '#fff',
    border: 'none',
    borderRadius: '50px',
    cursor: 'pointer',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 1px 2px',
    minWidth: '50px',
    paddingInline: '16px',
    padding: '10px',
    fontSize: '20px'
  },
  saveText:{
    marginLeft: '5px'
  },
  addButton: {
    position: 'fixed',
    bottom: '20px',
    left: '105px',
    backgroundColor: 'rgb(19, 96, 161)', // שינוי צבע הרקע פעם אחת בלבד
    color: '#fff',
    border: 'none',
    borderRadius: '50px',
    cursor: 'pointer',
    margin: '0 15px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 1px 2px',
    minWidth: '50px',
    paddingInline: '16px',
    padding: '10px',
    fontSize: '20px'
  }, inputCheckbox: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  editTitleLabel: {
    right: 0,
    top: '2px',
    transform: 'scale(0.85) translateY(-0.5rem) translateX(2rem)',
    paddingBottom: '0',
    paddingRight: '0'
  },
  editTitleIput: {
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px',
    borderRadius: '5px',
  }
};

