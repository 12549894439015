import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faChevronUp, faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons';

const EditCard = ({
  item,
  isOpen,
  toggleCard,
  onChange,
  onDelete,
  headerFields,
  cardConfig,
  showAddAndRemoveButton = true,
  openAlways = false
}) => {
  const formatTimestamp = (timestamp) => {
    if (timestamp && typeof timestamp === 'object' && '_seconds' in timestamp) {
      const date = new Date(timestamp._seconds * 1000);
      return date.toLocaleString();
    }
    return timestamp;
  };
  return (
    <div style={{ ...styles.cardContainer, ...(item.display == false ? styles.displayCard : {}) }}>
      <div style={styles.cardHeader} onClick={() => !openAlways && toggleCard(item.id)}>
        <div style={styles.containerHeader}>
          <div style={styles.cardTitle}>        
          {headerFields.map((field) => (
            <span key={field} style={styles.marginLeft5 }>
              {item[field] ? item[field] : ''}
              {field === 'index' ? '.' : '' || ''}
            </span>
          ))}
          </div>
          <FontAwesomeIcon icon={faGripVertical} style={{ ...styles.gripDotsContainerIcon, ...styles.marginLeft10 }} />
        </div>
        {!openAlways && ( // Only show the arrow if openAlways is false
          isOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />
        )}
      </div>
      <div style={styles.cardContent(openAlways || isOpen)}>
        {Object.keys(item).map((key) => {
          const config = cardConfig[key];
          if (!config || !config.show) {
            return null;
          }
          const label = config.label || key;
          const value =
            typeof item[key] === 'object' && item[key] !== null && '_seconds' in item[key]
              ? formatTimestamp(item[key])
              : typeof item[key] === 'object' && item[key] !== null
                ? JSON.stringify(item[key])
                : item[key];

          return (
            <div style={styles.cardList} key={key}>
              {config.inputType === 'checkbox' ? (
                <div
                  style={styles.inputCheckbox}
                  className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={value}
                    onChange={(e) => onChange(item.id, key, e.target.checked)}
                    onClick={(e) => e.stopPropagation()}
                    disabled={config.disable}
                  />
                </div>
              ) : config.inputType === 'textarea' ? (
                <textarea
                  style={styles.textareaField}
                  value={value}
                  placeholder={config.placeholder}
                  onChange={(e) => onChange(item.id, key, e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  disabled={config.disable}
                />
              ) : config.inputType === 'dropdown' ? (
                <select
                  style={styles.dropdownField}
                  value={value}
                  onChange={(e) => onChange(item.id, key, e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  disabled={config.disable}
                >
                  {config.options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  style={styles.inputField}
                  type="text"
                  value={value}
                  placeholder={config.placeholder}
                  onChange={(e) => onChange(item.id, key, e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  disabled={config.disable}
                />
              )}
              <label style={styles.label}>:{label}</label>
            </div>
          );
        })}
        {showAddAndRemoveButton && (
          <div style={styles.deleteIcon}>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={(e) => {
                e.stopPropagation(); // Prevents the card from toggling when clicking delete
                onDelete(item.id);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditCard;

const styles = {
  label: {
  },
  cardContainer: {
    width: '100%',
    margin: '7px auto',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px',
    borderRadius: 5,
    background: 'white',
  },
  displayCard: {
    backgroundColor: '#f0f0f0bf',
    color: '#b0b0b0bd',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px',
    cursor: 'pointer',
    flexDirection: 'row-reverse',
  },
  cardTitle: {
    margin: 0,
    fontSize: '1.2em',
    textAlign: 'rtl',
    maxWidth: '70vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  deleteIcon: {
    color: '#e74c3c',
    cursor: 'pointer',
    maxWidth: '10px',
    textAlign: 'center',
    marginTop: '15px'
  },
  dropdownField: {
    margin: '5px 0',
    padding: '5px',
    width: '60%',
    border: 'none',
    borderRadius: '4px',
    direction: 'rtl',
    background: 'white',
  },
  cardContent: (isOpen) => ({
    padding: '15px',
    display: isOpen ? 'block' : 'none',
  }),
  inputField: {
    margin: '5px 0',
    padding: '5px',
    width: '60%',
    border: 'none',
    direction: 'rtl'
  },
  textareaField: { // New textarea styling
    margin: '5px 0',
    padding: '5px',
    width: '60%',
    minHeight: '70px',
    border: 'none',
    direction: 'rtl',
    resize: 'none'
  },
  containerHeader: {
    display: 'flex',
    alignItems: 'center',
    maxWidth:'95%'
  },
  containerTitle:{
    maxWidth:'90%'
  },
  inputCheckbox: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  cardList: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    borderBottom: '1px solid #eeeeee96',
  },
  marginLeft5: {
    marginLeft: '5px',
  },
  marginLeft10: {
    marginLeft: '10px',
  },
};
